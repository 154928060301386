'use client'
import { observer } from 'mobx-react-lite'
import { ReactNode, useContext, useMemo } from 'react'

import { useBreakpoint } from '@/lib/hooks/use-breakpoint'
import { RootStoreContext } from '@/lib/stores/root-store'
import { cn } from '@/lib/utils/cn'

import { DEFAULT_WIDTH } from '../blocks/shared/constants'

type Props = {
  children: ReactNode
  hasBackground?: boolean
  className?: string
  maxWidth?: number
  hasSidebar?: boolean
  isSite?: boolean
}

export const FloatingAction = observer(
  ({
    children,
    hasBackground = true,
    className,
    maxWidth = DEFAULT_WIDTH,
    hasSidebar,
    isSite,
  }: Props) => {
    const {
      siteSidebarStore: { getDesktopSidebarWidth },
    } = useContext(RootStoreContext)

    const breakpoint = useBreakpoint()
    const isDesktop = ['lg', 'xl'].includes(breakpoint)

    const left = useMemo(() => {
      if (isDesktop && hasSidebar) {
        return getDesktopSidebarWidth
      }
      return 0
    }, [isDesktop, hasSidebar, getDesktopSidebarWidth])

    return (
      <div
        className={cn(
          'fixed inset-x-0 bottom-0 z-10 px-4 pt-1',
          {
            'bg-card/50 backdrop-blur-sm': hasBackground,
            'bottom-[64px]!': isSite && !isDesktop,
          },
          className,
        )}
        style={{
          paddingBottom: 'max(4px, calc(2 * env(safe-area-inset-bottom)))',
          left,
        }}
      >
        <div className="mx-auto w-full" style={{ maxWidth }}>
          {children}
        </div>
      </div>
    )
  },
)
